$(function() {
  /* initialise slider */
  $('.slider').slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000
  });

  /* set first slider article to active on load */
  $('.slider-articles').children().eq(0).addClass('current');

  /* set slider article to active on slide change */
  if ($(window).width() > 480) {
    $('.slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      $('.slider-article').removeClass('current');
      $('.slider-articles').children().eq(nextSlide).addClass('current');
    });
  }

  /* hamburger button and menu */
  $('.hamburger-button').click(function(event) {
    $(this).toggleClass('active');
    $('.hamburger-menu').toggleClass('active');
  });
});